html {
    background:  rgba(243, 255, 253, 0.65);
}

#okta-sign-in {
    border: none;
    margin-top: -200px
}

#okta-sign-in div.auth-content {
    padding: 45px;

}

#okta-sign-in.auth-container h2 {
    color: #0AC6C9;
    font-size: 2em
}

#okta-sign-in .okta-sign-in-header {
    
    display: none
}

#okta-signin-submit.button.button-primary {
    background: linear-gradient(to right, #FA7268, #FFB0AA);
    border: 0;
    width: 120px;
    margin: auto;
    padding: 0px

}

#form143 .button.button-primary {
    background: linear-gradient(to right, #FA7268, #FFB0AA);
    border: 0;
}



/* #okta-sign-in.auth-container .button-primary:hover {
    background-color: #FA7268;
    background-size: 300%;
    background-clip: border-box;
    animation: hue 3s ease-in-out infinite;
    cursor: pointer;
}

@keyframes hue {
    0% {
        background-position: 0 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0 50%;
    }

  } */

