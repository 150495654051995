
#header {
    background-image: url("../../assets/sleigh-background-teal-pink-dashboard.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    height: 400px;
    padding: 30px 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start
}


#main-nav {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-left: auto
}

.nav-item i {
    color: white;
    margin: 0 30px
}

.fas {
    font-size: 27px
}

#content {
    min-height: calc(100vh);
}

.content-item {
    padding: 40px 80px;
    margin-top: -150px;
}

.lead {
    font-family: 'Montserrat', Verdana, Geneva, Tahoma, sans-serif;
    text-align: center;
    color: #555
}

.lead a {
    color: #0AC6C9;
    font-weight: bold
}

.auth-btn {
    font-size: 0.85em;
    font-family: 'Montserrat', Verdana, Geneva, Tahoma, sans-serif;
    padding: 10px 30px;
    border-radius: 5px;
    border: 0;
    color: white;
    background: #FA7268;
    cursor: pointer
}

.logout-btn {
    padding: 5px 10px;
    color: #FA7268;
    background: #fff;
    margin-left: 20px;
    font-weight: bold

    /* position: fixed;
    bottom: 35px;
    right: 50px;
    box-shadow: 5px 5px 15px rgba(0,0,0,0.2) */
}

.logout-btn:hover {
    background: #FA7268;
    color: #fff;
}

.login-btn {
    display: block;
    margin: auto
}

footer {
    background: url("../../assets/sleigh-teal-pink-footer.svg") no-repeat;
    background-size: cover;
    padding: 20px;
    color: #555;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 0.9em;
    text-align: center;
}

@media (max-width: 1250px) {
    #header {
        background-size: auto 700px;

    }

}

@media (max-width: 950px) {
    .content-item {
        padding: 40px;
    }
}
