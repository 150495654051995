* {
  box-sizing: border-box

}

html, body {
  margin: 0;
}

html {
  background-color: white;
  height: 100%;

 /* background-color: rgba(244, 251, 252, 0.9) */
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  color: #555;
}

#logo {
  display: block;
  width: 110px;
  height: 65px;
}

#lg-logo {
  display: block;
  width: 180px;
  height: 130px;
  padding: 30px
}

.dir-link {
  font-family: "Montserrat", Verdana;
  font-weight: bold;
  color: #41B3A3;
  text-decoration: none;
  font-weight: bolder;
  margin: 20px 0;
  font-size: 1.05em;
  background: #FFF;
  width: 80px;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 5px 5px 20px rgba(97, 225, 208, 0.4);

}

.dir-link:hover {
  background: #41B3A3;
  color: #FFF
}

LoginBanner {
  grid-area: banner;
}

Login {
  grid-area: form;
}


.login-page-container {
  display: grid;
  grid-template-columns: 60% 40%;
  grid-template-areas: "banner form";
  width: 100%;
  height: 100vh;
  justify-content: space-between;
  background-image: linear-gradient(to bottom, rgba(28, 196, 199, 0.8), rgba(28, 196, 199, 0.7), 
  rgba(28, 196, 199, 0.7), rgba(97, 225, 208, 0.7), rgba(97, 225, 208, 0.7), rgba(160, 239, 228, 0.7), #FFF)
}


@media (max-width: 900px) {
  .login-page-container {
    grid-template-columns: 100%;
    grid-template-areas: 
      "banner"
      "form";
    justify-content: center;
    background-image: linear-gradient(to bottom, rgba(28, 196, 199, 0.8), rgba(28, 196, 199, 0.7), 
    rgba(97, 225, 208, 0.7), rgba(97, 225, 208, 0.7), rgba(160, 239, 228, 0.7))
  }
  
}

@media (max-width: 700px) {
  #logo {
    width: 90px;
    height: 55px;
  }
    
}



