.modal-form-container {
    padding: 30px;
    padding-bottom: 20px;
    background: rgb(247, 247, 247);
    border-radius: 8px;
    margin: auto;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 99;
  }
  
.modal-main {
    position:fixed;
    width: 50%;
    height: auto;
    min-height: 263px;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
}

.modal-form-container .form-title {
    display: inline-block;
    width: 100%;
    padding-bottom: 10px;
    border-bottom: 0.5px solid #cfcfcf; 
}

.confirmation-text {
    display: flex;
    margin: auto;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: 3%
}

.confirmation-text h3 {
    font-family: 'Montserrat', Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 100
}

.form-done {
    display: block;
    margin: 20px auto
}

form {
    padding: 10px;
    position: relative;
    font-family: 'Montserrat', Verdana, Geneva, Tahoma, sans-serif
}

label {
 display: inline-block;
 font-size: 0.75em;
}

fieldset {
    border: none;
    width: 100%;
    display: inline-block;
}

fieldset > .input-field,
fieldset > select  {
    width: 180px;
    margin: 10px 20px;
    padding: 3px;
    background: #fff;
    font-family: 'Montserrat', Verdana, Geneva, Tahoma, sans-serif;
    font-size: 0.75em;

}

.form-btn {
    padding: 7px 15px;
    color: #FA7268;
    background: #fff;
    border-radius: 5px;
    border: 0;
    cursor: pointer;
    font-family: 'Montserrat', Verdana, Geneva, Tahoma, sans-serif

    
}

.form-btn:hover {
    background: #FA7268;
    color: #fff
}

.form-btn + .form-btn {
    margin-left: 10px
}

.form-btn-container {
    margin-top: 10px;
    margin-left: 70%
}

@media (max-width: 1320px) {
    fieldset > .input-field,
    fieldset > select  {
        width: 150px
    }
}

@media (max-width: 1200px) {
    fieldset > .input-field,
    fieldset > select  {
        width: 90%
    }

    .form-btn-container {
        margin: 10px;
        display: flex;
        justify-content: center
        
    }

}