
.preview {
    width: 900px;
    height: 900px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.4);
    margin: auto;
    margin-top: -200px;
    padding: 70px;
    font-family: "Montserrat", Verdana, sans-serif;
}

.preview div {
    margin: 80px auto;
}


.none-selected {
    text-align: center;
    color: #555
}

.none-selected h2 {
	font-size: 2rem;
	letter-spacing: 1px;
	text-align: center;
    color: #0AC6C9;
    background: linear-gradient(to right, #FA7268, #FFB0AA);
    background-size: 300%;

    /* background: linear-gradient(to right, #0AC6C9, #61E1D0, #A0EFE4); */

    background-clip: text;
	-webkit-text-fill-color: transparent;
	animation: hue 3s ease-in-out infinite;
}

@keyframes hue {
    0% {
        background-position: 0 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0 50%;
    }

  }

.project-table {
    margin-top: -600px;
    z-index: 999;
    text-align: center
}

@media (max-width: 950px) {
    .preview {
        width: 700px;
        height: 700px;
        padding: 50px;
        font-size: 0.9em
    }

    .project-table {
        margin-top: -450px;
        
    }
}

@media (max-width: 750px) {
    .preview {
        width: 500px;
        height: 500px;
        padding: 30px;
        font-size: 0.8em
    }

    .preview h2 {
        font-size: 2em

    }

    .project-table {
        margin-top: -250px;
        
    }

}

@media (max-width: 560px) {
    .preview {
        width: 300px;
        height: 300px;
        padding: 15px;
        font-size: 0.7em
    }

    .preview h2 {
        font-size: 1.8em

    }

    .project-table {
        margin-top: -110px;
        
    }
}