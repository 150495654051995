.fc-left, .fc-right, .fc-center {
    font-family: "Montserrat", Verdana;
    font-weight: bold;
    font-size: 0.9em
}

.fc-center {
    font-size: 0.63em
}


.fc-toolbar.fc-header-toolbar {
    margin-bottom: 10px;
    height: fit-content
}


.fc-dayGridWeek-view .fc-content-skeleton,
.fc-dayGridDay-view .fc-content-skeleton {
  /* there may be week numbers in these views, so no padding-top */
  padding-bottom: 0;
  /* ensure a space at bottom of cell for user selecting/clicking */ }



.fc-unthemed td.fc-today {
    background: rgba(98, 98, 98, 0.1);
}


.fc-dayGrid-view .fc-body .fc-row {
    min-height: 2em;
    height: 3.5em;
    /* ensure that all rows are at least this tall */ 
}


.fc-row table {
    margin: 16px
}

.fc th, .fc td {
    border: 0;
    padding: 0;
    vertical-align: top;
    font-size: 0.7em
}


.fc-dayGrid-view .fc-row table td,
.fc-dayGrid-view .fc-row th {
    width: 60px; 
}

.fc table {
    width: 96%;
}

.fc-unthemed .fc-list-empty,
td.fc-widget-header,
tr.fc-list-item > td {
    padding: 15px
}

t.fc-list-table {
    font-family: "Montserrat", Verdana;
    font-size: 1.2em;
    height: 50px;
    padding-top: 20px
}
