.prod-progress-content {
    display: flex;
    justify-content: center;
    padding: 10px 20px;
    flex-direction: column;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    min-height: 700px

}


.prog-details {
    margin: 0 20px;    
}

/* table tr:nth-child(even){
    background-color: #F3FFFD;
} */


.fa-exclamation-circle {
    color: #FA7268
}

.task-name {
    min-width: 200px
}

.popup-alert {
    font-weight: 200;
    font-size: 0.9em;
    padding: 10px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 5px 5px 15px rgba(98, 98, 98, 0.15);
    position: absolute;
    display: none;
    width: 150px
}

.alert-flag {
    display: none;
    justify-content: center;
    margin: 15px auto;
}

.alert-flag p {
    display: inline-block;
    margin: 0 10px;
    font-weight: bolder;
    font-family: 'Montserrat', Verdana, Geneva, Tahoma, sans-serif;
}

.add-new-container {
    width: fit-content;
    cursor: pointer;
}

@media (max-width: 545px) {
    .prod-progress-content {
        padding: 0
    }

    .prog-details {
        margin: 0
    }

    td:nth-of-type(3),
    td:nth-of-type(4),
    th:nth-of-type(3),
    th:nth-of-type(4) {
        display: none
    }

    .calendar {
        display: none
    }

}

@media (max-width: 370px) {
    .prog-details {
        font-size: 0.8em
    }
}

