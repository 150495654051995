
.table-container {
    box-shadow: 5px 5px 15px rgba(97, 225, 208, 0.3);
    font-family: "Montserrat", Verdana, sans-serif;
    text-align: left;

}

.table-header {
    padding: 10px 20px;
    color: #fff;
    background-image: linear-gradient(to right, rgba(10, 198, 201, 0.86), rgba(10, 198, 201, 0.86)
    , rgba(97, 225, 208, 0.86),  rgba(160, 239, 228, 0.86))
}

.table-content {
    padding: 20px;
    background: #fff;
    min-height: 400px
}

.table-content h2 {
    text-align: center;
    margin: 100px;
    font-family: "Montserrat", Verdana, sans-serif;
    color: rgba(0, 0, 0, 0.2);
}


.add-circle-btn {
    display: inline-block;
    margin: 20px;
    margin-right: 10px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    background-color: #FA7268;
    padding: 4px
}

.add-circle-btn .fas {
    font-size: 10px;
}

.add-link {
    font-size: 0.8em;
    font-weight: bolder

}

table {
    font-family: "Montserrat", Verdana, sans-serif;
    margin: 15px auto;
    border:0; 
    border-collapse:separate; 
    border-spacing:0 5px;
}

table tr {
    color: #555;
    font-size: 1.1em
}

thead {
    text-align: left;
}

thead tr th{
    border-bottom: 0.5px solid #cfcfcf; 
    border-collapse:separate; 
    border-spacing: 2px 2px;
} 


th {
    font-size: 1em;
    padding: 20px 0;
}





td, th {
    font-size: 0.9em;
    width: 400px;
    padding-left: 20px

}

ul {
    padding: 0

}

li {
    list-style: none;
}

li + li {
    margin-top: 30px
}

li.service {
    display: inline-block;
    background: rgba(219, 219, 219, 0.5);
    color: #555;
    padding: 6px 8px;
    border-radius: 20px;
    font-weight: 100;
    font-size: 0.9em;
    margin: 1px;
}

li.service + li.service {
    margin-right: 8px;
    margin-top: 8px
}

.clickableColVal {
    padding: 10px 
}

.clickableColVal:hover {
    background: rgba(250, 114, 104, 0.9);
    border-radius: 12px;
    color: white;
    cursor: pointer;
}

.mainCol {
    display: flex;
    font-weight: bold;
    /* padding-right: 200px;  */
}

.popup-contract {
    font-size: 0.9em;
    padding: 0px 10px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 5px 5px 20px rgba(98, 98, 98, 0.07);
    max-width: 200px;
    display: none;
    position: absolute;
    margin-top: 20px
    
}

@media (max-width: 950px) {
    td, th {
        font-size: 0.8em;
        width: 200px;
        padding-left: 10px
    
    }
}
