.dashboard-container {
    margin-top: -250px;
    color: rgba(0,0,0,0.8)
}

.project-header {
    display: flex;
    flex-direction: row;
}

.project-title {
    margin-right: auto;
}

.project-name {
    font-family: "Montserrat", Verdana;
    color: rgba(0,0,0,0.8)
}



.status-desc {
    display: block;
    margin: 0;
    font-size: 0.8em;

}

.invoice-btn {
    border: 0;
    padding: 10px;
    background-color: #FA7268;
    color: white;
    font-family: "Montserrat", Verdana;
    text-align: left;
    width: 220px;
    font-size: 0.85em;
    height: 50px;
    margin-top: 20px;
}

table {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

table tr {
    text-align: left;
}

table td,
table th
 {
    padding: 10px;
    font-size: 0.65em
}

table tr:nth-child(even) {
    background: rgba(230, 230, 230, 0.1);
}



.lg-items-container {
    grid-area: lg;
    display: flex;
    flex-direction: column
}

.sm-items-container {
    grid-area: sm;
    display: flex;
    flex-direction: column

}

.container {
    padding: 20px;
    border: none;
    border-radius: 5px;
    background: white;
    box-shadow: 5px 5px 25px rgba(97, 225, 208, 0.2);
    margin: 5px 0;
    font-family: Verdana, Geneva, Tahoma, sans-serif
}

.container h4 {
    margin: 0;
    width: fit-content;
    font-family: "Montserrat", Verdana;
    font-weight: bold;
    font-size: 1.1em;
    color: rgba(0,0,0,0.8)
}

.dashboard-content {
    padding: 40px 0px;
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    grid-template-areas: 
        "lg sm";
    grid-gap: 10px;
    margin-top: -20px
}

.staffName a {
    color: #41B3A3;
    cursor: pointer
}

.messaging-app,
.gantt-chart,
.calendar {
    min-height: 450px
}

.comingsoon {
    text-align: center;
    padding: 20px;
    margin: auto;
    margin-top: 18%;
    font-size: 1.4em;
    font-family: 'Montserrat', Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
    color: rgba(65, 179, 163,0.5)

}

.messaging-app .comingsoon {
    margin-top: 30%;

}

.container.contact {
    display: flex;
    flex-direction: row;
}

.container.contact h4 {
    margin-top: 5px
}

.slack-link {
    background: url("../../assets/Slack-logo/SlackRGB.png") no-repeat;
    background-size: contain;
    width: 85px;
    height: 35px;
    cursor: pointer;
    display: inline-block;
    border: 0
}

@media (max-width: 1400px) {
    .dashboard-content {
        grid-template-columns: 1.3fr 1fr;
    }
}

@media (max-width: 1310px) {
    .dashboard-content {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 1150px) {
    .content-item {
        padding: 40px 30px;
    }
}

@media (max-width: 1035px) {
    .staff-list {
        grid-area: staff
    }

    .calendar {
        grid-area: calendar
    }

    .contact {
        grid-area: contact
    }

    .sm-items-container{
        display: grid;
        grid-template-columns: 1fr 1.7fr;
        grid-template-areas: 
            "staff calendar"
            "contact contact"
    }

    .calendar {
        flex: 2
    }

    .staff-list {
        flex: 1
    }

    .container.staff-list {
        margin-right: 20px
    }

    .dashboard-content {
        display: flex;
        flex-direction: column-reverse
    }

    .messaging-app,
    .gantt-chart,
    .calendar {
        min-height: 400px
    }
}

@media (max-width: 838px) {
    .sm-items-container{
        display: flex;
        flex-direction: column;
    }

    .dashboard-content {
        flex-direction: column
    }

    .container.staff-list {
        margin-right: 0px
    }

}
